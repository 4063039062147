import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import ProductHero from "../../components/ProductHero";
import FindLO from "../../components/FindLO";
import Calculators from "../../components/Calculators";
import ProductDetails from "../../components/ProductDetails";

import background from "../../images/downpaymentAssistanceBg.jpg";
import icon from "./Icons_DownpAssist_active.png";
import styles from "./styles.module.less";

const DownPaymentProgram = ({ data }) => {
    const header = <Header active={["down-payment-assistance-programs"]} />;
    const intro = "Down Payment Assistance Programs";
    const subhead =
        "If you can’t do it alone, have a little help from your friends.";

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>
                    Down Payment Assistance Programs | Bay Equity Home Loans
                </title>
                <meta
                    name="description"
                    content="Learn about down payment assistance programs for eligible homebuyers who need help making that first, big payment. Contact us today to find out if you qualify."
                />
                <meta
                    name="keywords"
                    content="down payment assistance,down payment assistance programs,first time home buyer down payment assistance,fha down payment assistance,first time home owners down payment assistance,down payment assistance grants,first time home buyers down payment assistance programs,mortgage down payment assistance,down payment assistance programs for fha loans,fha loan down payment assistance,what is down payment assistance,conventional loan down payment assistance,home buying down payment assistance,down payment assistance programs for homebuyers"
                />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <ProductHero
                customPhoto={background}
                icon={icon}
                header={intro}
                subhead={subhead}
                alt="Down Payment Assistance Program | Bay Equity Home Loans"
            />
            <ProductDetails
                title={"What is mortgage down payment assistance?"}
                border={true}>
                <p>
                    With so many expenses involved with buying your first home,
                    down payments can be hard to come by. Luckily, there are a
                    few very helpful down payment assistance programs for
                    eligible homebuyers who need a financial nudge
                    over&nbsp;the&nbsp;hill.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"What are the benefits of down payment assistance?"}
                border={true}>
                <p>
                    We always recommend a down payment of anywhere between
                    5-20%. But that’s a lot of money. So it’s not uncommon for
                    people to use down payment assistance programs for
                    homebuyers because they can help you purchase a home, even
                    if it’s slightly out&nbsp;of&nbsp;reach.
                </p>
            </ProductDetails>
            <ProductDetails
                title={"How do I qualify for a fixed-rate mortgage?"}>
                <p>
                    Down payment assistance grants vary from state to state,
                    which means the requirements can also change. If you’re
                    needing a little financial nudge in
                    the&nbsp;right&nbsp;direction,{" "}
                    <a className={styles.link} href="/find-your-loan-officer">
                        talk to one of our loan officers
                    </a>
                    . They can help you find the down payment assistance program
                    you might need to buy the home you&nbsp;really&nbsp;want.
                </p>
            </ProductDetails>
            <Calculators
                showCalculatorMortgage={true}
                showCalculatorRentVsBuy={true}
                showCalculatorHomeAffordability={true}
                showCalculatorRequiredIncome={true}
            />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="down-payment-assistance-programs"
                />
            )}
        </Layout>
    );
};

export default DownPaymentProgram;

export const pageQuery = graphql`
    query DownPaymentProgram {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["Down Payment Assistance Products"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
